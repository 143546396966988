import React from 'react'
import './contact.css'
import {MdOutlineMail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs.sendForm('service_7yb9vwe', 'template_dfm6tj5', form.current, 'ZMTMT_7yu83Swb9GW')
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)


    e.target.reset();
  
  };



  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>

        <div className='contact__options'>

        <article className='contact__option'>
        <MdOutlineMail className="contact__option-icon"/>
          <h4>Email</h4>
          <h5>garry.stevens99@gmail.com</h5>
          <a href="mailto:garry.stevens99@gmail.com" target="_blank">Send a message!</a>
        </article>

        <article className='contact__option'>
        <RiMessengerLine className="contact__option-icon"/>
          <h4>Messenger</h4>
          <h5>Facebook</h5>
          <a href="https://m.me/garry.stevens99" target="_blank">Send a message!</a>
        </article>

        <article className='contact__option'>
        <BsWhatsapp className="contact__option-icon"/>
          <h4>What's App</h4>
          <h5>+447000000000</h5>
          <a href="https://api.whatsapp.com/send?phone+447000000000" target="_blank">Send a message!</a>
        </article>
        </div>

        {/* END OF CONTACT OPTIONS */}


        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>
    </section>
  )
}

export default Contact