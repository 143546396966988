import React from 'react'
import './portfolio.css'

import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/LZ1.png'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'


// DATA

const data = [

{
id:1,
image: IMG1,
title: 'Cloud Cost Management Project ',
github: 'https://github.com',
demo: ''
},

{
  id:2,
  image: IMG2,
  title: 'APM Implementation (Dynatrace)',
  github: 'https://github.com',
  demo: ''
  },
    
{
  id:3,
  image: IMG4,
  title: 'Landing Zone Implementation',
  github: 'https://github.com',
  demo: ''
  },
  
{
  id:4,
  image: IMG5,
  title: 'Cloud Hosting Team Transformation',
  github: 'https://github.com',
  demo: ''
  },


  {
    id:5,
    image: IMG6,
    title: 'Donation Platform PCI Assessment',
    github: 'https://github.com',
    demo: ''
    },

    {
      id:6,
      image: IMG3,
      title: 'Trello Implementation',
      github: 'https://github.com',
      demo: ''
      },
  
]



const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>

          <div className='portfolio__item-cta'>
            <a href={github} className='btn'>Github</a>
            <a href={demo} className="btn btn-primary" target='_blank'>Live Demo</a>
          </div>
        </article>
        )
        })
      }

      </div>
    </section>
  )
}

export default Portfolio