import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>

      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
     
{/* ARTICLE ONE */}
    <article className="service">

      <div className="service__head">
        <h3>Technical Implementation</h3>
      </div>

      <ul className='service__list'> 
        <li>
          <BiCheck className='service__list-icon' />
          <p>Cloud Implementation
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>AWS Landing Zone
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Infrastructure As Code
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>CI / CD
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Security Operations
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Application Performance Management
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Vulnerability Management
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>HA / DR
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Load Testing and Quality Assurance
          </p>
        </li>
      </ul>

    </article>


{/* ARTICLE TWO */}

<article className="service">

      <div className="service__head">
        <h3>Consultancy</h3>
      </div>

      <ul className='service__list'> 
        <li>
          <BiCheck className='service__list-icon' />
          <p>Cloud Strategy
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Security
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>High Volume traffic / campaigns 
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Site Reliability
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>System Integration 
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Networking 
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Cost Optimisation 
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Content Management Systems
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Architecture Design / Tech Lead
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Secure Hosting
          </p>
        </li>
      </ul>

    </article>


    {/* ARTICLE THREE */}

    <article className="service">

      <div className="service__head">
        <h3>Leadership | Managerial</h3>
      </div>

      <ul className='service__list'> 
        <li>
          <BiCheck className='service__list-icon' />
          <p>Team Management
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Strategic Partnerships
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Governance
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Agile Methodology
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Supplier Management
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Contract Negotiations
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Team Building
          </p>
        </li>

        <li>
          <BiCheck className='service__list-icon' />
          <p>Technical Project Planning
          </p>
        </li>
      </ul>

    </article>

</div>



    </section>
  )
}

export default Services