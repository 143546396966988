import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I have ...</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">

        <div className="experience__frontend">
          <h3>Technical</h3>

          <div className="experience__content">

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Amazon Web Services</h4>
                <small>8+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Containerization</h4>
                <small>8+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Linux Sysadmin</h4>
                <small>20+ Years</small>
              </div>
            </article>


            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Scripting</h4>
                <small>20+ Years</small>
              </div>
            </article>


            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Infrastructure As Code</h4>
                <small>5+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Automation</h4>
                <small>3+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Web Hosting</h4>
                <small>20+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Systems Security</h4>
                <small>20+ years</small>
              </div>
            </article>


          </div>

        </div>
        

        {/* END OF FRONTEND */}

        <div className="experience__backend">
          <h3>Managerial</h3>

          <div className="experience__content">

          <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Team Management</h4>
                <small>10+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Training Facilitation</h4>
                <small>8+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Operational Support</h4>
                <small>20+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Supplier Managment</h4>
                <small>12+ years</small>
              </div>
            </article>


            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Large Budget Management</h4>
                <small>10+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Strategic Leadership</h4>
                <small>5+ years</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Mentoring</h4>
                <small>10+ years</small>
              </div>
            </article>


          </div>
          

        </div>

      </div>




    </section>
  )
}

export default Experience
