import React from 'react'
import './testimonials.css'

// useState and useEffect for data state management.
import {useState, useEffect} from "react";

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import loading spinner.
import ClipLoader from "react-spinners/ClipLoader";



const Testimonials = () => {


// Will use this for dynamic sites
// console.log('debug:current URL 👉️', window.location.hostname);


// define the initial state
const [pages, setPages] = useState(null);


useEffect(() => {

  const query = `{
    pfTestimonialCollection(where: 
      {siteId_contains: "garrystevens.link"} )
      { 
      items {
        name
        role
        company
        review
        linkedIn
        avatar
         {
          url
        }     
      }
    }
  }
  `;


  window
    .fetch(process.env.REACT_APP_CONTENTFUL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_CONTENTFUL_API_TOKEN,
      },
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    
    .then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }

      setPages(data.pfTestimonialCollection.items);

    });

}, []);




  // show a loading screen case the data hasn't arrived yet
  if (!pages) {
    return (
    <div className='loader_container'> 
  <ClipLoader 
    type="Circles"
    color="#242424"
    height={100}
    width={100}
    class="cliploader"
    timeout={30000} //30 secs
    />
    </div> 
    );
    
  }

  return (
    <section id='testimonials'>
      <h5>Review from associates</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>

        {
          pages.map(({avatar, name, role, company, review, linkedIn}, index) => {

            return (
              <SwiperSlide key={index} className="testimonial">
                <div className='client__avatar'>
                  <img src={avatar.url}/>
                </div>
                <h5 className='client__name'>{name}</h5>
                <h5 className='client__role'>({role} - {company})</h5>
                <small className='client__review'>{review}</small>
                {
                linkedIn != null &&
                <a href={linkedIn}>LinkedIn</a>
                }
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}



export default Testimonials