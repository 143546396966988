import React from 'react'
import './about.css'
import ME from '../../assets/me.jpg'
import ME2 from '../../assets/me2.png'

import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
   <section id='about'>
     <h5>Get to know</h5>
     <h2>About Me</h2>

     <div className='container about__container'>
       <div className="about__me">
         <div className="about__me-image">
           <img src={ME2} alt="About Image" />
         </div>
       </div>


       <div className="about__content">
         <div className="about__cards">
           
           <article className='about__card'>
             <FaAward className='about__icon'/>
             <h5>Experience</h5>
             <small>20+ Years technical and managerial experience in engineering across operations, delivery and support.</small>
           </article>
          
           <article className='about__card'>
             <FiUsers className='about__icon'/>
             <h5>Clients</h5>
             <small>Providing expertise and support across a range of clients and technologies</small>
           </article>
           
           <article className='about__card'>
             <VscFolderLibrary className='about__icon'/>
             <h5>Project Delivery</h5>
             <small>A diverse list of projects delivered, often playing a key role from conception, to closure into support.</small>
           </article>

        </div>

           <p>
          Engineering Manager ,  20+ years commercial experience with a passion for technology, people and progress.
         
           <br/>
           <br/>


           “The only way to make sense out of change is to plunge into it, move with it, and join the dance." ― Alan Watts
           
           </p>

           <a href="#contact" className='btn btn-primary'>Let's Talk</a>


       </div>

     </div>
   </section>
  )
}

export default About